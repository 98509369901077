import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Breadscrum from "../components/Breadscrum"
import Block from "../components/Block"

const OpKremaceInner = styled.div`
  width: calc(100% - 50px);
  margin: 0 auto;
  padding-bottom: 30px;
  max-width: 1150px;
  margin-top: 25px;

  @media (min-width: 900px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  h1 {
    font-family: NewKansas;
    font-size: 26px;
    margin-bottom: 40px;
    color: #000;
    margin-top: 20px;

    @media (min-width: 900px) {
      font-size: 30px;
    }
  }

  h2 {
    font-family: Visuelt-Medium;
    font-size: 22px;
    margin-bottom: 25px;
    margin-top: 40px;
    color: #000000;
  }

  p {
    margin-bottom: 20px;
    font-family: Visuelt-Regular;
    line-height: 25px;
    font-size: 16px;
  }
`

const ObchodniPodminkyKremace = ({ data }) => {
  return (
    <Layout V2>
      <SEO
        title="Obchodní podmínky"
        description="Obchodní podmínky jsou nedílnou součástí smlouvy o zprostředkování a odchylná ujednání ve smlouvě o zprostředkování mají přednost před ustanoveními obchodních podmínek."
        noindex={true}
        image={"/pohrebnisluzbaOg.png"}
      />

      <OpKremaceInner>
        <Breadscrum
          visible={true}
          stranky={[
            { name: "Domů", url: "/" },
            { name: "Pohřební služba", url: "/pohrebni-sluzba/" },
            { name: "Obchodní podmínky", url: "/obchodni-podminky/" },
          ]}
        />
        <h1>Obchodn&iacute; podm&iacute;nky společnosti Goodbye s.r.o.</h1>
        <div className="podminkyInner">
          <p>
            <span>Tyto obchodní podmínky (dále jen </span>
            <strong>"obchodní podmínky"</strong>
            <span>
              ) společnosti Goodbye s.r.o., se sídlem Na Kopci 164, 251 62
              Svojetice, identifikační číslo 08319588, zapsané v obchodním
              rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 316877
              (dále jen{" "}
            </span>
            <strong>"zprostředkovatel"</strong>
            <span>
              ), upravují v souladu s ust. § 1751 odst. 1 Sb., občanský zákoník,
              ve znění pozdějších předpisů (dále jen{" "}
            </span>
            <strong>"občanský zákoník"</strong>
            <span>
              ) práva a povinnosti smluvních stran vzniklé v souvislosti nebo na
              základě smlouvy o zprostředkování služeb uzavírané mezi
              zprostředkovatelem a zájemcem prostřednictvím webového rozhraní,
              jehož je zprostředkovatelem provozovatelem. Webové rozhraní je
              zprostředkovatelem provozováno na internetové adrese
              www.goodbye.cz.
            </span>
          </p>
          <p>
            <span>
              Obchodní podmínky jsou nedílnou součástí smlouvy o zprostředkování
              a odchylná ujednání ve smlouvě o zprostředkování mají přednost
              před ustanoveními obchodních podmínek.
            </span>
          </p>
          <p>
            <span>
              Předmětem služby je poskytování pohřebních služeb, které
              zprostředkovatel nabízí prostřednictvím webového rozhraní.
              Portfolio služeb a jejich cena je dána nabídkou zprostředkovatele
              (balíčkem jednotlivých služeb) zveřejněnou na webovém rozhraní
              goodbye.cz. Zprostředkovatel je oprávněn ustanovení těchto
              podmínek měnit či doplňovat. Nové podmínky jsou účinné dnem jejich
              zveřejnění na webovém rozhraní goodbye.cz. Smluvní vztah mezi
              zprostředkovatelem a zájemcem se řídí obchodními podmínkami
              účinnými k okamžiku doručení objednávky ze strany zájemce
              zprostředkovateli. Pro objednání služeb zájemce vyplní závazně
              objednávkový formulář ve webovém rozhraní obchodu (dále jen
              "objednávka"). Pokud bude zájemce požadovat služby nad rámec
              nabídky zprostředkovatele zveřejněné na webovém rozhraní
              goodbye.cz, musí své požadavky uvést v objednávkovém formuláři.
              Zprostředkovatel učiní prostřednictvím e-mailu zájemci cenovou
              nabídku, která musí být zájemcem potvrzena e-mailem. Takto
              sjednané služby včetně cenové nabídky musí být dodatečně potvrzeny
              písemně.
            </span>
          </p>
          <p>
            <span>
              Před zasláním objednávky zprostředkovateli je zájemci umožněno
              zkontrolovat objednávku a měnit údaje, které do objednávky vložil,
              a to i s ohledem na možnost zájemce zjišťovat a opravovat chyby
              vzniklé při zadávání dat do objednávky. Objednávku odešle zájemce
              zprostředkovateli kliknutím na příslušné tlačítko. Údaje uvedené v
              objednávce jsou zprostředkovatelem považovány za správné.
              Zprostředkovatel neprodleně po obdržení objednávky její přijetí
              zájemci potvrdí, a to elektronickou poštou na adresu elektronické
              pošty zájemce uvedenou v uživatelském rozhraní či v objednávce
              (dále jen "elektronická adresa zájemce"). Zprostředkovatel je vždy
              oprávněn v závislosti na charakteru objednávky (specifické
              požadavky) požádat zájemce o dodatečné potvrzení objednávky
              (například písemně).
            </span>
          </p>
          <p>
            <strong>Realizace služeb</strong>
          </p>
          <p>
            <span>Služba funguje on-line 24 hodin denně.</span>
          </p>
          <p>
            <span>
              Předpokladem zahájení poskytování služby je úhrada služby a
              vyplnění objednávky umístěné na webovém rozhraní.
            </span>
          </p>
          <p>
            <span>
              Dokončením služby se rozumí předání urny do rukou zájemce nebo
              vyzvednutí urny zájemcem. V případě neuhrazení plné ceny se na
              detailech předání urny domluví zájemce se zprostředkovatelem až po
              připsání částky v plné výši na bankovní účet
              zprostředkovatele.&nbsp;
            </span>
          </p>
          <p>
            <strong>Zájemce</strong>
          </p>
          <p>
            <span>
              Zájemcem může být právnická osoba anebo fyzická osoba, která
              dosáhla věku 18 let a je plně způsobilá k právním jednáním, která
              souvisí s těmito obchodními podmínkami, dá souhlas se zpracováním
              osobních údajů, řádně vyplní objednávku na goodbye.cz a uhradí
              zprosředkovateli odměnu ve výši, která je předpokladem pro
              zahájení poskytování služby.
            </span>
          </p>
          <p>
            <strong>Uzavření smlouvy</strong>
          </p>
          <p>
            <span>
              Dokončením objednávky a jejím odesláním zájemce stvrzuje, že je
              seznámen s obchodními podmínkami a souhlasí s nimi. Objednávka je
              návrhem smlouvy zprostředkování pohřebních služeb. Smlouva o
              zprostředkování pohřebních služeb je uzavřena okamžikem, kdy bude
              objednávka zájemce přijata zprostředkovatelem, tedy kdy bude
              zájemci doručeno přijetí jeho objednávky zprostředkovatelem.
            </span>
          </p>
          <p>
            <span>
              V objednávce je zájemce povinen uvést přesné a pravdivé údaje.
              Pokud dojde ke změně údajů u zájemce, je zájemce takovou změnu
              povinen bezodkladně oznámit zprostředkovateli a to na e-mail
              podpora@goodbye.cz. Zprostředkovatel je oprávněn odmítnout
              objednávku a neposkytnout požadovanou službu, pokud se může
              odůvodněně domnívat, že zájemce porušuje tyto obchodní podmínky či
              je v minulosti porušil.
            </span>
          </p>
          <p>
            <strong>Záruky</strong>
          </p>
          <p>
            <span>
              Zprostředkovatel odpovídá zájemci za to, že dodávané služby jsou
              ve shodě se smlouvou, zejména, že jsou bez vad. Shodou se smlouvou
              se rozumí, že dodané služby byly poskytnuty v souladu se smlouvou
              a služba má jakost a užitné vlastnosti smlouvou požadované,
              zprostředkovatelem, dodavatelem, nebo jeho zástupcem popisované,
              nebo na základě jimi dodaných informací očekávané, popřípadě
              jakost a užitné vlastnosti pro služby takového druhu obvyklé, že
              odpovídá požadavkům právních předpisů.
            </span>
          </p>
          <p>
            <span>
              V případě, že služba při převzetí zájemcem není ve shodě se
              smlouvou, tj. má vady (dále jen „rozpor se smlouvou“), má
              objednatel právo na to, aby zprostředkovatel bezplatně a bez
              zbytečného odkladu provedl nápravu její opravou; není-li takový
              postup možný, může objednatel požadovat přiměřenou slevu z ceny
              nebo od smlouvy odstoupit. To neplatí, pokud objednatel před
              převzetím služby o rozporu se smlouvou věděl nebo rozpor se
              smlouvou sám způsobil. Rozpor se smlouvou, který se projeví během
              šesti (6) měsíců ode dne převzetí služby, se považuje za rozpor
              existující již při jejím převzetí a dodání služeb, pokud to
              neodporuje povaze věci nebo pokud se neprokáže opak.
            </span>
          </p>
          <p>
            <span>
              Práva objednatele vyplývající z odpovědnosti zprostředkovatele za
              vady, uplatňuje objednatel u zprostředkovatele písemně na adresu
              jeho sídla, elektronickou poštou na adresu podpora@goodbye.cz.
            </span>
          </p>
          <p>
            <strong>Spotřebitelská smlouva</strong>
          </p>
          <p>
            <span>
              O spotřebitelskou smlouvu se jedná v případě, že zájemce je
              spotřebitelem, tj. pokud je fyzickou osobou a poskytnutí služby je
              objednáno zájemcem mimo rámec jeho podnikatelské činnosti nebo
              mimo rámec samostatného výkonu jeho povolání.
            </span>
          </p>
          <p>
            <span>
              V případě spotřebitelské smlouvy se na zájemce vztahuje ochrana
              spotřebitele dle právních předpisů.
            </span>
          </p>
          <p>
            <span>
              Je-li zájemce spotřebitelem a vznikne-li mezi zprostředkovatelem a
              zájemcem spor ze smlouvy, který se zprostředkovateli a zájemci
              nepodaří vyřešit přímo, má zájemce právo obrátit se s tímto sporem
              na Českou obchodní inspekci (www.coi.cz). Práva spotřebitelů hájí
              i jejich zájmová sdružení a jiné subjekty na jejich ochranu. Toto
              právo může zájemce uplatnit nejpozději do 1 roku ode dne, kdy u
              zprostředkovatele uplatnil poprvé právo, které je předmětem tohoto
              spotřebitelského sporu.
            </span>
          </p>
          <p>
            <strong>Cena a platební podmínky</strong>
          </p>
          <p>
            <span>
              Cena za zprostředkování služby je cenou smluvní, je uvedena na
              internetových stránkách goodbye.cz. Zájemce odesláním objednávky
              potvrzuje souhlas s cenou.
            </span>
          </p>
          <p>
            <span>
              Cenu je možno hradit: bezhotovostně platební kartou, anebo
              bezhotovostně převodem na účet zprostředkovatele číslo
              5735717339/0800, anebo, jakýmkoliv jiným způsobem, který platební
              brána nabízí.
            </span>
          </p>
          <p>
            <span>Zájemce bere na vědomí, že </span>
            <strong>
              v případě neuhrazení ceny déle než 30 dní po splatnosti faktury je
              pohledávka postoupena na externího inkasního pracovníka s náklady
              20 % z celkové ceny
            </strong>
            <span>.&nbsp;</span>
          </p>
          <p>
            <span>
              V případě bezhotovostní platby je třeba uvést variabilní symbol
              platby, uhrazení ceny nastává okamžikem jejího připsání na účet
              zprostředkovatele.
            </span>
          </p>
          <p>
            <span>
              Zprostředkovatel vystaví zájemci fakturu - daňový doklad, a to po
              uhrazení ceny služeb a zašle jej v elektronické podobě na
              elektronickou adresu zájemce.
            </span>
          </p>
          <p>
            <strong>Odstoupení od smlouvy</strong>
          </p>
          <p>
            <span>
              Objednávku lze zrušit a od smlouvy odstoupit pouze ve lhůtách a za
              podmínek uvedených v těchto podmínkách.
            </span>
          </p>
          <p>
            <span>
              Objednávku lze stornovat odesláním stornovacího emailu na
              podpora@goodbye.cz. Do emailu je zájemce povinen uvést číslo
              objednávky.
            </span>
          </p>
          <p>
            <span>
              Od spotřebitelské smlouvy uzavřené mezi zprostředkovatelem a
              zájemcem může zájemce odstoupit ve lhůtě 14 dnů ode dne uzavření
              zprostředkovatelské smlouvy. Oznámení o odstoupení od smlouvy je
              třeba zaslat na email podpora@goodbye.cz. Přijetí oznámení o
              odstoupení od smlouvy zprostředkovatel bez zbytečného odkladu
              emailem potvrdí. Odstoupením od smlouvy se smlouva od počátku ruší
              a hledí se na ni, jakoby nebyla uzavřena. V případě odstoupení od
              smlouvy zprostředkovatel vrátí realizovanou platbu, kterou od
              zájemce na základě smlouvy přijal, a to do 14 dnů od odstoupení od
              smlouvy, a to na účet, ze kterého byla platba odeslána
              zprostředkovateli nebo na účet uvedený v odstoupení od smlouvy. V
              souladu s ust. § 1837 občanského zákoníku nelze od smlouvy
              odstoupit v případě, že služba byla splněna s výslovným souhlasem
              zájemce před uplynutím lhůty pro odstoupení od smlouvy, tento
              důsledek zájemce bere na vědomí souhlasem s poskytnutím služby
              před uplynutím lhůty pro odstoupení od smlouvy. V takovém případě
              nelze přijatou platbu vrátit.
            </span>
          </p>
          <p>
            <span>
              Zprostředkovatel si vyhrazuje právo od smlouvy odstoupit v
              případě, že:
            </span>
          </p>
          <p>
            <span>
              - službu není z objektivních příčin možné za původních podmínek
              poskytnout,
            </span>
          </p>
          <p>
            <span>
              - nebyly předem poskytnuty veškeré důležité informace týkající se
              rozsahu a specifikace služby,
            </span>
          </p>
          <p>
            <span>
              - plnění se stane objektivně nemožným nebo protiprávním,
            </span>
          </p>
          <p>
            <span>
              - v případě podstatného porušení těchto obchodních podmínek
              zájemcem.
            </span>
          </p>
          <p>
            <span>
              V případě, že nastala některá z výše uvedených skutečností,
              zprostředkovatel bude zájemce o odstoupení zprostředkovatele od
              smlouvy neprodleně informovat a to na email, ze kterého byla
              odeslána objednávka. Odstoupení je vůči zájemci účinné okamžikem,
              kdy je zájemci doručeno. Zprostředkovatel vrátí přijatou platbu
              bezhotovostně na účet, ze kterého zájemce úhradu provedl, a to do
              5 dnů od odstoupení od smlouvy.
            </span>
          </p>
          <p>
            <strong>Informace o ochraně osobních údajů</strong>
          </p>
          <p>
            <span>
              Zprostředkovatel svou informační povinnost vůči zájemci ve smyslu
              čl. 13 Nařízení Evropského parlamentu a Rady 2016/679, o ochraně
              fyzických osob v souvislosti se zpracováním osobních údajů a o
              volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné
              nařízení o ochraně osobních údajů) (dále jen "nařízení GDPR")
              související se zpracováním osobních údajů zájemce pro účely plnění
              Smlouvy o zprostředkování služby, pro účely jednání o Smlouvě o
              zprostředkování služby a pro účely plnění veřejnoprávních
              povinností zprostředkovatele plní zprostředkovatel prostřednictvím
              zvláštního dokumentu.
            </span>
          </p>
          <p>
            <strong>Ostatní informace</strong>
          </p>
          <p>
            <span>
              Při uzavírání smlouvy jsou použity prostředky komunikace na dálku.
              Náklady vzniklé při použití prostředků komunikace na dálku hradí
              zájemce sám. Veškerá korespondence související se smlouvou mezi
              zprostředkovatelem a zájemcem probíhá v písemné formě, a to
              emailem.
            </span>
          </p>
          <p>
            <span>
              Zájemce bere na vědomí, že programové vybavení a další součásti
              tvořící webové rozhraní obchodu (včetně fotografií nabízených
              služeb) jsou chráněny autorským právem. Zájemce se zavazuje, že
              nebude vykonávat žádnou činnost, která by mohla jemu nebo třetím
              osobám umožnit neoprávněně zasahovat či neoprávněně užít
              programové vybavení nebo další součásti tvořící webové rozhraní
              obchodu.
            </span>
          </p>
          <p>
            <span>
              Zájemce není oprávněn při využívání webového rozhraní obchodu
              používat mechanismy, programové vybavení nebo jiné postupy, které
              by mohly mít negativní vliv na provoz webového rozhraní. Webové
              rozhraní obchodu je možné užívat jen v rozsahu, který není na úkor
              práv ostatních zákazníků zprostředkovatele a který je v souladu s
              jeho určením.
            </span>
          </p>
          <p>
            <span>
              Zájemce bere na vědomí, že zprostředkovatel nenese odpovědnost za
              chyby vzniklé v důsledku zásahů třetích osob do webové stránky
              nebo v důsledku užití webové stránky v rozporu s jejich určením.
            </span>
          </p>
          <p>
            <span>
              V případě, že některé ustanovení těchto obchodních podmínek je
              neplatné, neúčinné nebo nepoužitelné (nebo se takovým stane),
              použije se namísto něj ustanovení, které se svým smyslem nejvíce
              blíží neplatnému, neúčinnému či nepoužitelnému stanovení.
              Neplatností, neúčinností nebo nepoužitelností jednoho ustanovení
              není dotčena platnost ostatních ustanovení.
            </span>
          </p>
          <p>
            <span>
              Tyto obchodní podmínky jsou platné a účinné od 7.2.2022.
            </span>
          </p>
          <p>
            <span>Jiří Štěpánek</span>
          </p>
          <p>
            <span>Goodbye s.r.o.</span>
          </p>
        </div>
      </OpKremaceInner>
    </Layout>
  )
}

export default ObchodniPodminkyKremace
